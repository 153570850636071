import request from "superagent";
import { backendBaseUri } from "../consts";

export function setMetaData(itemId, data) {
    return new Promise((resolve, reject) => {
        request.post(backendBaseUri + "/metadata/api/v1/items/" + itemId + "/metadata/general")
            .set("Authorization", "Bearer " + sessionStorage.getItem("accessToken"))
            .send({metadata: data})
            .then(res => {
                resolve(res);
                console.log(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getMetaData(itemId) {
    return new Promise((resolve, reject) => {
        request.get(backendBaseUri + "/metadata/api/v1/items/" + itemId + "/metadata/general")
            .set("Authorization", "Bearer " + sessionStorage.getItem("accessToken"))
            .then(res => {
                console.log(res);
                resolve(res.body.metadata);
            })
            .catch(err => {
                reject(err);
            });
    });
}

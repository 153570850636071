import React, { Component } from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import * as contentApi from "./api/content";
import * as metaDataApi from "./api/metadata";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

export default class Gallery extends Component {

    state = {
        newDialog: false,
        editDialog: false,
        elements: [],
        currentItem: undefined,
        name: "",
        birthday: "",
        phoneNo: "",
        avatar: undefined
    };

    handleClickOpen = (dialogId, itemId) => () => {
        this.setState({
            name: "",
            birthday: "",
            phoneNo: "",
            avatar: undefined
        });

        if (itemId) {
            metaDataApi.getMetaData(itemId).then(metadata => {
                if (metadata) {
                    this.setState({
                        name: metadata.name ? metadata.name : "",
                        birthday: metadata.birthday ? metadata.birthday : "",
                        phoneNo: metadata.phoneNo ? metadata.phoneNo : ""
                    });
                }
            });
        }

        this.setState({
            [dialogId]: true,
            currentItem: itemId
        });
    };

    handleClose = dialogId => () => {
        this.setState({ [dialogId]: false });
    };

    createElement = () => {
        contentApi.upload("root", this.state.file)
            .then(itemId => metaDataApi.setMetaData(itemId, {
                name: this.state.name,
                birthday: this.state.birthday,
                phoneNo: this.state.phoneNo
            }))
            .then(this.getData);

        this.setState({ newDialog: false });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    updateElement = () => {
        metaDataApi.setMetaData(this.state.currentItem, {
            name: this.state.name,
            birthday: this.state.birthday,
            phoneNo: this.state.phoneNo
        });

        this.state.elements.find(element => element.itemId === this.state.currentItem).name = this.state.name;
        this.state.elements.find(element => element.itemId === this.state.currentItem).birthday = this.state.birthday;
        this.state.elements.find(element => element.itemId === this.state.currentItem).phoneNo = this.state.phoneNo;
        this.setState({ editDialog: false });
    };

    getData = () => {
        contentApi.getChildIds("root").then(childIds => {
            const elements = childIds.map( childId => {
                // get metadata
                metaDataApi.getMetaData(childId).then(metadata => {
                    if (metadata) {
                        const element = this.state.elements.find(element => element.itemId === childId);
                        element.name = metadata.name;
                        element.birthday = metadata.birthday;
                        element.phoneNo = metadata.phoneNo;
                        this.setState({});
                    }
                });

                return {
                    itemId: childId,
                    name: "",
                    author: "author"
                };
            });

            this.setState({ elements });
        });
    }

    onChange = e => {
        this.setState({ file: e.target.files[0] });
    };

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        for (const element of this.state.elements) {
            // get image
            if (!this.state[element.itemId]) {
                contentApi.download(element.itemId).then(image => this.setState({[element.itemId]: image}));
            }
        }
    }

    render() {
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        margin: 10,
                        flexWrap: "wrap"
                    }}
                >
                    {this.state.elements.map(card => (
                        <Card key={card.itemId} style={{ margin: 10, minWidth: 250, maxWidth: 400 }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={this.state[card.itemId] ? URL.createObjectURL(this.state[card.itemId]) : undefined}
                                    title={card.name}
                                    style={{ height: 140, width: "auto" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {card.name}
                                    </Typography>
                                    <Typography component="p">
            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
            across all continents except Antarctica
                                        <br />
                                        <TextField id="read-only-name" label={card.name} margin="normal" InputProps={{ readOnly: true }} />
                                        <TextField id="read-only-birthday" label={card.birthday} margin="normal" InputProps={{ readOnly: true }} />
                                        <TextField id="read-only-phoneno" label={card.phoneNo} margin="normal" InputProps={{ readOnly: true }} />
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={this.handleClickOpen("editDialog", card.itemId)}>
                                    Edit
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                </div>
                <Fab color="primary" aria-label="Add" style={{position: "fixed", bottom: 20, right: 20 }} onClick={this.handleClickOpen("newDialog")}>
                    <AddIcon />
                </Fab>
                <Dialog
                    open={this.state.newDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">New Friend</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                  Fill the fields with your data
                        </DialogContentText>
                        <CardMedia component="img" src={this.state.avatar} style={{ height: 140, width: "auto" }} />
                        <TextField autoFocus margin="dense" label="Name" type="text" fullWidth value={this.state.name} onChange={this.handleChange("name")} />
                        <TextField margin="dense" label="Geburtstag" type="date" InputLabelProps={{ shrink: true }} fullWidth value={this.state.birthday} onChange={this.handleChange("birthday")} />
                        <TextField margin="dense" label="Telephonnummer" type="text" fullWidth value={this.state.phoneNo} onChange={this.handleChange("phoneNo")} />
                        <br /><br /><br />
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={this.onChange}
                            style={{display: "none"}}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span">
                                Choose picture
                            </Button>
                        </label>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose("newDialog")} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.createElement} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.editDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Edit Friend</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill the fields with your data
                        </DialogContentText>
                        <CardMedia component="img" src={this.state.avatar} style={{ height: 140, width: "auto" }} />
                        <TextField autoFocus margin="dense" label="Name" type="text" fullWidth value={this.state.name} onChange={this.handleChange("name")} />
                        <TextField margin="dense" label="Geburtstag" type="date" InputLabelProps={{ shrink: true }} fullWidth value={this.state.birthday} onChange={this.handleChange("birthday")} />
                        <TextField margin="dense" label="Telephonnummer" type="text" fullWidth value={this.state.phoneNo} onChange={this.handleChange("phoneNo")} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose("editDialog")} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.updateElement} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

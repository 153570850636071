import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./Main";
import CodeToToken from "./CodeToToken";

function App() {

    return (
        <Router>
            <div>
                <Route exact path="/" component={Main} />
                <Route path="/callback" component={CodeToToken} />
            </div>
        </Router>
    );
}

export default App;

import request from "superagent";
import {clientId as CLIENT_ID, clientSecret as CLIENT_SECRET} from "../consts";

const json = req => req
    .type("json")
    .accept("json");

const auth = (req, accessToken) => req.set("Authorization", `Bearer ${accessToken}`);

export const authenticate = ({ backendBaseUri, frontendReturnUri, authCode }) => request
    .post(`${backendBaseUri}/auth/token`)
    .type("form")
    .accept("json")
    .auth(CLIENT_ID, CLIENT_SECRET)
    .send({ grant_type: "authorization_code" })
    .send({ client_id: CLIENT_ID })
    .send({ redirect_uri: frontendReturnUri })
    .send({ code: authCode });

export const me = ({ backendBaseUri, accessToken }) => {
    let req = request.get(`${backendBaseUri}/auth/api/v1/users/me`);
    req = auth(req, accessToken);
    req = json(req);
    return req;
};

export const logoutUser = ({ backendBaseUri, accessToken }) => {
    const req = request.post(`${backendBaseUri}/auth/api/v1/users/me/logout`)
        .type("application/json");
    auth(req, accessToken);
    req.send({});
    return req;
};

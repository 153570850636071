import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Main from "./Main";
import { backendBaseUri, frontendBaseUri } from "./consts";
import { authenticate, me } from "./api/auth";

const tokenRequest = (
    backendBaseUri,
    frontendReturnUri,
    authCode,
) => new Promise((resolve, reject) => {
    authenticate({ backendBaseUri, frontendReturnUri, authCode })
        .then((resp) => {
            const accessToken = resp.body.access_token;
            const idToken = resp.body.id_token;
            const refreshToken = resp.body.refresh_token;
            const expiresAt = Date.now() + resp.body.expires_in * 1000;

            const tokens = {
                accessToken,
                idToken,
                refreshToken,
                expiresAt
            };

            resolve(tokens);
        })
        .catch(error => reject(error));
});

const getUserInfo = (backendBaseUri, accessToken) => new Promise((resolve, reject) => {
    me({ backendBaseUri, accessToken })
        .then(resp => resolve(resp && resp.body))
        .catch(error => reject(error));
});

class CodeToToken extends Component {

    state = {
        accessToken: undefined
    }

    render() {
        if (!this.state.accessToken) {
            const urlParams = new URLSearchParams(window.location.search);
            const authCode = urlParams.get("code");
            if (authCode) {
                tokenRequest(backendBaseUri, `${frontendBaseUri}/callback`, authCode)
                    .then((tokens) => {
                    //this.props.setAuthTokens(tokens);
                        sessionStorage.setItem("accessToken", tokens.accessToken);
                        this.setState({accessToken: tokens.accessToken});

                        getUserInfo(backendBaseUri, tokens.accessToken)
                            .then((userInfo) => {
                                //this.props.setAuthUser(userInfo);
                            });
                    })
                    .catch((error) => {
                        sessionStorage.removeItem("accessToken");
                    });
            }

        } else {
            this.props.history.push("/");
        }

        return null;
    }
}

export default withRouter(CodeToToken);

import React, { Component } from "react";
import "./App.css";
import Gallery from "./Gallery2";
import Button from "@material-ui/core/Button";
import { UserManager } from "oidc-client";
import { clientId, backendBaseUri, frontendBaseUri } from "./consts";


const config = {
    authority: `${backendBaseUri}/auth`,
    client_id: clientId,
    redirect_uri: `${frontendBaseUri}/callback`,
    response_type: "code"
};

const userManager = new UserManager(config);


class Main extends Component {

handleLogin = () => {
    userManager.signinRedirect();
};

render() {
    const accessToken = sessionStorage.getItem("accessToken");

    return (

        <div className="Appp">
            {accessToken ?

                <Gallery />
                : <div style={{textAlign: "center"}}>

                    <br />
                    <br /><br />

                    <Button variant="contained" color="primary" onClick={this.handleLogin}>
        Log in
                    </Button>

                </div>
            }
        </div>
    );
}
}

export default Main;

import request from "superagent";
import { backendBaseUri } from "../consts";

export function upload(parentId, file) {
    return new Promise((resolve, reject) => {
        request.put(backendBaseUri + "/content/api/v1/items/" + parentId + "/content")
            .set("Content-Type", "binary")
            .set("Authorization", "Bearer " + sessionStorage.getItem("accessToken"))
            .set("Filename", file.name)
            .send(file)
            .then(res => {
                console.log(res);
                resolve(res.body.id);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function download(itemId) {
    return new Promise((resolve, reject) => {
        request.get(backendBaseUri + "/content/api/v1/items/" + itemId + "/content")
            .set("Authorization", "Bearer " + sessionStorage.getItem("accessToken"))
            .responseType("blob")
            .then(res => {
                console.log(res);
                resolve(res.body);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function getChildIds(parentId) {
    return new Promise((resolve, reject) => {
        request.get(backendBaseUri + "/content/api/v1/items/" + parentId + "/children")
            .set("Authorization", "Bearer " + sessionStorage.getItem("accessToken"))
            .then(res => {
                console.log(res);
                resolve(res.body.filter(child => child.itemtype === "itemtype:document").map(child => child.id));
            })
            .catch(err => {
                reject(err);
            });
    });
}
